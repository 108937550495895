.public_recruitment .card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: none;
    border-radius: 15px;
    display: inline-block;
    margin: 2%;
    /* box-shadow: 0 0px 14px -11px rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%); */
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.07);
    text-align: left;
    /* padding: 5px 0 7px; */
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
}
.public_recruitment .card-body {
    padding: 21px;
}
.public_recruitment .card-title {
    margin-bottom: 0.5rem;
    font-size: 13px;
    font-weight: 600;
    font-family: "PoppinsSemiBold";
    /* text-transform: capitalize; */
}
.public_recruitment .card-text {
    font-size: 12px;
    position: relative;
    margin: 15px 0px 35px 0;
    font-family: "PoppinsLight";
    line-height: 19px;
    text-align: justify;
}

.public_recruitment .card-text:last-child {
    margin-bottom: 0;
    font-size: 12px;
    font-weight: 100;
}

.public_recruitment .calendarCountainer {
    background-color: #19b0d20f;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 33px;
    height: 33px;
    padding: 5px 8px;
    /* background-size: cover; */
}
.public_recruitment .dateText {
    display: inline-block;
    margin: 0 0 0 5px;
    font-weight: 100;
    font-size: 12px;
    color: #0e2038;
    font-family: "PoppinsLight";
    font-size: 12px;
}

.public_recruitment .CardBtn {
    font-size: 10px;
    width: 115px;
    height: 35px;
    background-color: #19b0d20f;
    border: none;
    border-radius: 20px;
    color: #19b0d2;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.public_recruitment .CardBtn .row {
    width: auto;
}

.public_recruitment .BtnTxt {
    flex: 5;
    font-family: "PoppinsLight";
    font-size: 10px;
}

.public_recruitment .CardBtn:hover {
    color: white;
    background-color: #19b0d2;
    transition: 0.3s;
    text-decoration: none !important;
}
.public_recruitment .readMoreLink:hover {
    text-decoration: none !important;
}

.public_recruitment .newTag {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAA0CAYAAADIZmusAAAABHNCSVQICAgIfAhkiAAAAklJREFUaEPtms9LVHEUxe99M2+0AiWZ0Be4qI3hFBL6PxgtHCMQWwUuIqiFYAgaLgINQgmhNq50Jwjh6GrAZVsjRlRoUYvUBnJI/B2O791uq6RU5t15F4a4s5nN95zvPedz+W5m0MssEfwHH7QgFUbRiFQYEDAiRkSpAVstpWLFtkZEXJ2S0IgoFSu2NSLi6pSERkSpWLGtERFXpyQ0IkrFim2NiLg6JaERUSpWbGtExNUpCY2IUrFiWyMirk5JaESUihXbGhFxdUpCI6JUrNjWiIirUxKqEiGgLDnwwgnA5fmfA2C7Uo7of0Pkfx/w/DTrO/HhzXTq48nBr8yt3I6TP8RnOpFTRRkqMiJE5CPgdNGh4UK65dN5Qybnck0u4RBruhExFkWg8oMQHPEgUxiLjXzrSH0NM1T9fO6aE+AAM3zIfBJhtH+fLSMIHQSAE74fHy3cb86XM0Ty3arnxo/7eSUf8cZdlHiFDsLrsE3ovD2sdl7v3En9kFx6lqYmu1J36edxHxE84ZWrDeNdchAOsIkI40WselNI39gNc0nYs5cXPtdW7+89ZV0vE0qWoi8hCG3wDo/li3UT0NV4WIppZGdm1i54ia3H/Aw+44fk6nm+ZwZhvF/4fXyVbzyahLa2YmTDSYwWF11vPdHDM/XzVlw/zeKfIPzGrwYIL7933JoGxEByr5pmhmKeu/yAkAa45OaT9/wJQvCBBx/Jp29m+Luy/1VHhN788j0IaJCf7dbfgdCbzb0HBzhAS1atSUXjhszSXQQa/AXT+BhQHvQUlgAAAABJRU5ErkJggg==);
    position: absolute;
    top: 0;
    right: 16px;
    padding: 13px 11px 20px 10px;
    background-size: contain;
    background-repeat: no-repeat;
    font-size: 13px;
    font-family: "PoppinsSemiBold";
    color: white;
}

.public_recruitment .CardFooter {
    /* position: absolute;
    bottom: 0;
    width: 95%;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 10px; */
    /* margin: 35px 0 0px 0; */
}
.public_recruitment .paginationCountainer {
    margin: 30px;
}
.public_recruitment .paginationCountainer > * {
    /* display: inline-block; */
    position: relative;
    margin: auto;
    width: fit-content;
    display: flex;
}

.public_recruitment .paginationCountainer > div > * {
    width: 30px;
    height: 30px;
    background-color: #19b0d22b;
    margin: 5px;
    border: none;
    color: #19b0d2;
    border-radius: 5px;
}
.public_recruitment .paginationCountainer > div > div {
    padding-top: 3px;
}
.public_recruitment .paginationCountainer > div > button:disabled,
.public_recruitment .paginationCountainer > div > button[disabled] {
    filter: grayscale(1);
    -webkit-filter: grayscale(1);
}
.public_recruitment .tableNbr {
    font-family: "PoppinsSemiBold";
    /* text-align: center; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.public_recruitment .titleSearch {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 97%;
    align-items: center;
    justify-content: space-between;
}
.public_recruitment .title {
    flex: 2;
    text-align: left;
}
.public_recruitment .search {
    display: flex;
    text-align: right;
    justify-content: center;
}
.public_recruitment .search > button,
.public_recruitment .search > button:focus {
    height: 52px;
    width: 52px;
    margin: 0 0 0 5px;
    background-color: #b9b9b926;
    border: none;
    box-shadow: none;
}
.public_recruitment .search > button:hover {
    background-color: #19b0d2;
}
.public_recruitment .btn-primary:not(:disabled):not(.public_recruitment .disabled):active,
.public_recruitment .search > button:focus {
    background-color: #b9b9b926;
}
.public_recruitment .search > input {
    height: 53px;
    width: 300px;
    border-radius: 10px;
    border: 2px solid #efefef;
    padding: 10px;
    font-size: 14px;
    color: #626060;
}
.public_recruitment .search > input:hover,
.public_recruitment .search > input:focus,
.public_recruitment .search > input:focus-visible {
    height: 53px;
    width: 300px;
    border-radius: 10px;
    border: 2px solid #19b0d2;
    padding: 10px;
    font-size: 14px;
    color: #626060;
}

.public_recruitment .search > input:focus-visible {
    outline: none;
}

.public_recruitment .fw-bold {
    font-family: "PoppinsBold";
}
.public_recruitment .allCards {
    display: flex;
    /* justify-content: center; */
}

@media screen and (max-width: 850px) {
    .public_recruitment .titleSearch {
        flex-direction: column;
        justify-content: center;
        width: 100%;
    }

    .public_recruitment .search {
        width: 100%;
    }

    .public_recruitment .allCards {
        justify-content: center;
    }
}

@media screen and (max-width: 400px) {
    .public_recruitment .search > button,
    .public_recruitment .search > button:focus {
        height: 52px;
        width: 52px;
    }

    .public_recruitment .search > input {
        width: 100%;
    }

    .public_recruitment .search > input:hover,
    .public_recruitment .search > input:focus,
    .public_recruitment .search > input:focus-visible {
        width: 100%;
    }

    .public_recruitment .allCards .col-lg-4 {
        padding: 0 !important;
    }
}
