.public_recruitment p {
    font-family: "PoppinsRegular";
    font-size: 13px;
}

.public_recruitment h3 {
    font-family: "PoppinsSemiBold";
    font-size: 18px;
}

.public_recruitment .SOheader {
    position: relative;
    background: #fff;
}

.public_recruitment .SOheader::before {
    content: "";
    position: absolute;
    background-color: #19b0d214;
    height: 250px;
    width: 100%;
    top: 0;
    left: 0;
}

.public_recruitment .SOheader > .container-sm {
    padding-top: 20px;
}

.public_recruitment .linkText {
    font-size: 10px;
    font-family: "PoppinsRegular";
    padding: 20px 0;
    color: #0e2038cf;
}

.public_recruitment .linkText > a,
.public_recruitment .linkText > strong {
    color: #0e2038cf !important;
    cursor: pointer;
    font-size: 10px;
    font-family: "PoppinsRegular";
}

.public_recruitment .SOheader > div > h2 {
    font-size: 20px;
    font-family: "PoppinsSemiBold";
}

.public_recruitment .Headerinfo {
    width: 50%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.public_recruitment .firstRowInfo {
    display: flex;
}

.public_recruitment .cntratType {
    display: flex;
    margin-right: 45px;
    /* flex: 1; */
}

.public_recruitment .cntratTypeImg {
    background-color: rgba(25, 176, 210, 0.06);
    border-radius: 50%;
    width: 50px;
    height: 50px;
}
.public_recruitment .cntratTypeImg img {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
}

.public_recruitment .cntratTypeTxt,
.public_recruitment .distTxt,
.public_recruitment .locTxt {
    margin: 0 0 0 5px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.public_recruitment .cntratTypeTxt > b {
    font-size: 12px;
    font-family: "PoppinsSemiBold";
}

.public_recruitment .cntratTypeTxt > p {
    font-size: 13px;
    margin: 0;
}

.public_recruitment .dist {
    display: flex;
}

.public_recruitment .distImg {
    background-image: url("../../../images/publicRecruitmentModule/Ellipse26.png");
    width: 40px;
    height: 40px;
    background-size: cover;
}

.public_recruitment .distTxt > b {
    font-size: 12px;
    font-family: "PoppinsSemiBold";
}

.public_recruitment .distTxt > p {
    font-size: 13px;
}

.public_recruitment .locRow {
    display: flex;
}

.public_recruitment .locImg {
    background-image: url("../../../images/publicRecruitmentModule/Ellipse26.png");
    width: 40px;
    height: 40px;
    background-size: cover;
}

.public_recruitment .locTxt > b {
    font-size: 12px;
    font-family: "PoppinsSemiBold";
}

.public_recruitment .locTxt > p {
    font-size: 13px;
    font-family: "PoppinsRegular";
    margin: 0;
}

.public_recruitment .SObody {
    /* max-width: 1000px; */
    margin: auto;
    padding-top: 32px;
    display: flex;
    margin-bottom: 50px;
}

.public_recruitment .SOrightBody {
    width: 50%;
    display: inline-block;
    padding: 10px;
    /* z-index: 9999; */
}

.public_recruitment .SOleftBody {
    width: 50%;
    display: inline-block;
    /* float: right; */
    padding: 5px;
    margin-top: 20px;
}

/* .public_recruitment .SOleftBody>h3 {
    font-size: 18px;
    padding: 10px 0;
} */

.public_recruitment .SOleftBody > h4 {
    font-size: 13px;
}

.public_recruitment .SOleftBody p {
    font-size: 13px;
    font-weight: 300;
}

.public_recruitment .missionList > li {
    list-style-type: none;
    margin: 0 0 10px;
}

.public_recruitment .missionList {
    padding: 0 0 0 15px;
    font-size: 13px;
    font-weight: 300;
}

.public_recruitment .missionList div {
    display: inline-block;
    margin: 0 0 0 5px;
}

.public_recruitment .svgImg {
    width: 27px;
    margin: 7px 0 0 7px;
}

.public_recruitment .job_section {
    margin-bottom: 50px;
}

.public_recruitment .job_section ul {
    font-size: 13px;
    font-family: "PoppinsRegular";
    list-style: none;
    margin-bottom: 50px;
}

.public_recruitment .job_section ul li {
    margin: 0 0 -28px 0;
    font-size: 13px;
}

.public_recruitment .job_section ul li::before {
    content: "\2022";
    color: #19b0d2;
    font-weight: bold;
    display: inline-block;
    width: 1.1em;
    font-size: 24px;
    /* margin-left: -1.1em; */
}

.public_recruitment .job_section p {
    margin: 0;
}

.public_recruitment .job_section p strong {
    font-size: 13px;
    font-family: "PoppinsSemiBold";
}

.recaptcha {
    margin: 0 auto;
    display: flex;
    justify-content: flex-start;
}

.recaptcha iframe {
    margin: 0 !important;
    display: flex;
    justify-content: center;
}

@media screen and (max-width: 992px) {
    .public_recruitment .Headerinfo {
        width: 100%;
    }

    .public_recruitment .cntratTypeImg {
        flex-shrink: 0;
    }

    .public_recruitment .SObody {
        flex-direction: column;
    }

    .public_recruitment .SOleftBody {
        width: 100%;
    }

    .public_recruitment .SOrightBody {
        width: 100%;
    }
}

@media screen and (max-width: 400px) {
    .public_recruitment .SOheader::before {
        height: 260px;
    }

    .public_recruitment .SOheader > .container-sm {
        padding-top: 20px;
    }

    .public_recruitment .SOrightBody {
        padding: 0;
    }

    .recaptcha {
        transform: scale(0.8);
        transform-origin: left;
    }
}

@media screen and (max-width: 336px) {
    .public_recruitment .SOheader::before {
        height: 330px;
    }
}
