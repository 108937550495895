.LoadingContainer{
  background-color: rgba(255, 255, 255, 0.3);
  height: 100vh;
  z-index: 10005;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
/* .Title {
  color: #18b1d4;
  font-size: 18px;
  font-weight: 900;
  padding: 30px;
} */