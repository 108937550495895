.public_recruitment > div {
    min-height: 75px;
}

@media screen and (max-width: 850px) {
    .public_recruitment > div {
        min-height: 45px;
    }
}

.public_recruitment .my-nav {
    z-index: 99999;
    box-shadow: #00000026 0 0 15px 0;
    padding: 0;
    background-color: #fff;
}

.public_recruitment .brand-text {
    font-size: 16px;
    font-family: Helvetica;
    font-weight: 500;
    position: absolute;
    margin-top: 10px;
    margin-left: 8px;
}

.public_recruitment .cand-spont-btn,
.public_recruitment .cand-spont-btn:focus {
    background-color: #19b0d2;
    border: none;
    box-shadow: none;
    border-radius: 5px;
    font-size: 14px;
    width: 197px;
    height: 42px;
    /* line-height: 42px; */
    font-family: "PoppinsRegular";
}

.public_recruitment .cand-spont-btn:hover,
.public_recruitment .cand-spont-btn:active {
    background-color: #1f8ba4;
    border: none;
    box-shadow: none;
}
.public_recruitment .brandImg {
    cursor: pointer;
}

.public_recruitment .header__items {
    /* margin: 15px 25px; */
    font-size: 16px;
    font-family: "PoppinsLight";
    padding-top: 15px;
}

.public_recruitment .TextActive {
    color: #19b0d2;
    text-transform: none;
    text-decoration: none;
    background-color: hsla(191, 79%, 46%, 0.06);
    border-bottom: 4px solid #19b0d2;
}

.public_recruitment .my-nav a {
    color: #000000;
    text-decoration: none;
    padding: 10px 5px;
    margin-right: 15px;
}

.public_recruitment .TextActive p {
    color: #19b0d2;
    font-size: 16px;
    font-family: "PoppinsSemiBold";
}

.public_recruitment .nav-item--links {
    justify-content: flex-end;
}

@media screen and (max-width: 850px) {
    .public_recruitment .nav--container {
        justify-content: center !important;
        /* height: 70px; */
    }

    .public_recruitment .nav-item--links {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
        justify-content: center;
        z-index: 9999999;
        background-color: white;
    }
}

@media screen and (max-width: 400px) {
    .public_recruitment .my-nav a {
        margin-right: 0;
    }

    .public_recruitment .my-nav a p {
        margin-right: 0;
        font-size: 12px;
    }
}
