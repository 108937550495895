:root {
    --blue: #007bff;
    --indigo: #6610f2;
    --purple: #6f42c1;
    --pink: #e83e8c;
    --red: #dc3545;
    --orange: #fd7e14;
    --yellow: #ffc107;
    --green: #28a745;
    --teal: #20c997;
    --cyan: #17a2b8;
    --white: #fff;
    --gray: #6c757d;
    --gray-dark: #343a40;
    --primary: #007bff;
    --secondary: #6c757d;
    --success: #28a745;
    --info: #17a2b8;
    --warning: #ffc107;
    --danger: #dc3545;
    --light: #f8f9fa;
    --dark: #343a40;
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
    --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
        "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji",
        "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
        "Courier New", monospace;
}
.public_recruitment > .newsletter {
    /* height: 284px; */
    width: 100%;
    background: linear-gradient(90deg, #13c4dd, #0a626f);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 2%;
}

.public_recruitment .newsletter__title h2 {
    font-size: 26px;
    font-family: "PoppinsBold";
    color: #fff;
}

.public_recruitment .newsletter__description p {
    font-size: 14px !important;
    font-family: "PoppinsLight" !important;
    max-width: 450px;
    margin: auto;
    padding: 2%;
    color: #fff;
}

.public_recruitment .newsletter__inputs {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
.public_recruitment .newsletter__inputs-form {
    margin-right: 10px;
}

.public_recruitment .newsletter__inputs-form input,
.public_recruitment .newsletter__inputs-form select {
    margin: 2% 0;
    height: 58px;
    max-width: 400px;
    width: 400px;
    font-size: 15px !important;
    font-family: "PoppinsRegular" !important;
    /* text-transform: capitalize!important; */
}

.public_recruitment .newsletter__inputs button,
.public_recruitment .newsletter__inputs button:focus {
    width: 137px;
    height: 60px;
    background: var(--unnamed-color-19b0d2) 0% 0% no-repeat padding-box;
    background: #19b0d2 0% 0% no-repeat padding-box;
    border-radius: 6px;
    border: none;
    box-shadow: none;
}

.public_recruitment .newsletter__inputs button:hover,
.public_recruitment .newsletter__inputs button:active {
    background: #1f8ba4 0% 0% no-repeat padding-box;
    border: none;
    box-shadow: none;
}

/* .public_recruitment .selectBoxOptions {
    text-transform: capitalize;
} */

.public_recruitment select:active,
.public_recruitment select:hover {
    outline-color: #19b0d2;
}

@media screen and (max-width: 850px) {
    .public_recruitment > .newsletter {
        padding: 10% 2%;
    }

    .public_recruitment .newsletter__inputs-form {
        margin-right: 0;
        width: 100%;
    }

    .public_recruitment .newsletter__inputs .btn {
        max-width: 400px;
        width: 100%;
    }

    .public_recruitment .newsletter__inputs-form input,
    .public_recruitment .newsletter__inputs-form select {
        margin: 2% auto;
        /* text-transform: capitalize!important; */
        width: 100%;
    }
}
