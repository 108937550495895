.public_recruitment hr {
    margin: 0;
}
.public_recruitment .Footer {
    margin-right: auto;
    margin-left: auto;
    padding: 55px 0;

    /* display: flex;
    flex-direction: row;
    justify-content: space-around;
    border-top: 1px solid #E3E3E3; */
}

.public_recruitment .Footer .row {
    width: 100%;
}

.public_recruitment .Footer .col {
    /* max-width: 400px; */
}

.public_recruitment .Copyright p {
    font-weight: normal;
    color: #7b97aa;
    font-size: 12px;
    margin: 10px 0px 0px 0px;
    display: inline-block;
    font-family: "PoppinsRegular";
}
.public_recruitment .Copyright {
    padding-top: 15px;
}

.public_recruitment .icon_facebook {
    display: inline-block;
    width: 26px;
    height: 24px;
    background-image: url(../../../images/publicRecruitmentModule/sprite_login.png);
    background-repeat: no-repeat;
    background-position: -45px -49px;
    margin-top: 7px;
    margin-left: 7px;
    margin-right: 5px;
    position: absolute;
}

.public_recruitment .icon_facebook:hover {
    display: inline-block;
    width: 26px;
    height: 24px;
    background-image: url(../../../images/publicRecruitmentModule/sprite_login.png);
    background-repeat: no-repeat;
    background-position: -45px -124px;
}

.public_recruitment .footer_h4 {
    font-size: 19px;
    color: #30597d;
    /* font-weight: 700; */
    text-transform: uppercase;
    margin-top: 20px;
    margin-bottom: 7px;
    font-family: "PoppinsRegular";
}
.public_recruitment .footer_h4 strong {
    font-weight: bolder;
    font-size: 19px;
    color: #30597d;
    font-family: "PoppinsRegular";
}

.public_recruitment .liens_footer {
    list-style: none;
    padding-left: 0px;
}
.public_recruitment .liens_footer li {
    display: inline-block;
    width: 100%;
}
.public_recruitment .liens_footer li::before {
    content: "\2022";
    color: #19b0d2;
    font-weight: bold;
    display: inline-block;
    width: 1.1em;
    font-size: 24px;
    /* margin-left: -1.1em; */
}

.public_recruitment .liens_footer a {
    text-decoration: none;
    font-size: 14px;
    font-family: "PoppinsLight";
    color: #8291a9;
    font-weight: normal;
    text-decoration: none;
    transition: all ease-in-out 200ms;
}
.public_recruitment .liens_footer a:hover {
    text-decoration: underline;
}

.public_recruitment .footer_arrow {
    width: 15px;
    height: 15px;
    background-image: url(../../../images/publicRecruitmentModule/sprite_login.png);
    background-repeat: no-repeat;
    background-position: 0px -86px;
    margin-top: 5px;
    margin-left: 0px;
    margin-right: 0px;
    float: left;
}

.public_recruitment .liens_footer_contact {
    list-style: none;
    padding-left: 0;
    margin: 1.2rem 0;
}

.public_recruitment .liens_footer_contact li {
    display: inline-block;
    width: 100%;
    margin-bottom: 6px;
}
.public_recruitment .liens_footer_contact li p,
.public_recruitment .liens_footer_contact li a {
    font-size: 14px;
    font-family: "PoppinsLight";
}

.public_recruitment .icon_tel {
    width: 26px;
    height: 24px;
    background-image: url(../../../images/publicRecruitmentModule/sprite_login.png);
    background-repeat: no-repeat;
    background-position: -45px 0px;
    margin-top: -2px;
    margin-left: 0px;
    margin-right: 5px;
    float: left;
}

.public_recruitment .icon_msg {
    width: 26px;
    height: 24px;
    background-image: url(../../../images/publicRecruitmentModule/sprite_login.png);
    background-repeat: no-repeat;
    background-position: -45px -24px;
    margin-top: -2px;
    margin-left: 0px;
    margin-right: 5px;
    float: left;
}

.public_recruitment .icon_site {
    width: 26px;
    height: 24px;
    background-image: url(../../../images/publicRecruitmentModule/sprite_login.png);
    background-repeat: no-repeat;
    background-position: -1px -113px;
    margin-top: -2px;
    margin-left: 0px;
    margin-right: 5px;
    float: left;
}

.public_recruitment .icon_adresse {
    width: 26px;
    height: 24px;
    background-image: url(../../../images/publicRecruitmentModule/sprite_login.png);
    background-repeat: no-repeat;
    background-position: -1px -142px;
    margin-top: -2px;
    margin-left: 0px;
    margin-right: 5px;
    float: left;
}

.public_recruitment .liens_footer_contact .email-img {
    max-width: 160px;
}

.public_recruitment .liens_footer_contact p {
    font-size: 11px;
    color: #8291a9;
    font-weight: normal;
    text-decoration: none;
    transition: all ease-in-out 200ms;
    line-height: 19px;
    margin: 0;
    font-family: "PoppinsRegular";
}

.public_recruitment .liens_footer_contact a:hover {
    /* font-size: 11px; */
    font-family: "PoppinsRegular";
    color: #8291a9;
    font-weight: normal;
    text-decoration: underline;
    transition: all ease-in-out 200ms;
}

.public_recruitment .liens_footer_contact a {
    font-size: 11px;
    font-family: open Sans;
    color: #8291a9;
    font-weight: normal;
    text-decoration: none;
    transition: all ease-in-out 200ms;
    font-family: "PoppinsRegular";
}

/*margin-right: auto;
    margin-left: auto;
    padding-left: 5px;
    padding-right: 5px;
    width: 960px;*/

@media screen and (max-width: 575px) {
    .public_recruitment .Footer {
        margin-right: auto;
        margin-left: auto;
        padding: 55px 3%;
    }
}
