.public_recruitment form {
    padding: 20px 32px;
    border-radius: 10px;
    box-shadow: 0px 0px 5px #8080808c;
    margin-top: -30%;
    margin-right: -10px;
    /* max-width: 450px; */
    /* float: right; */
    background-color: #fff;
    width: -webkit-fill-available;
}

@media screen and (max-width: 1005px) {
    .public_recruitment form {
        margin-right: 0px;
        float: none;
        margin-top: -15%;
    }
}

@media screen and (max-width: 992px) {
    .public_recruitment form {
        margin-top: 0;
    }
}

@media screen and (max-width: 400px) {
    .public_recruitment form {
        padding: 10px;
    }
}

.public_recruitment .input-container {
    height: 45px;
}

.public_recruitment iframe {
    height: 77px !important;
    margin-left: -16px;
}
.public_recruitment .col label {
    font-weight: 400;
    color: #808080;
}

.public_recruitment form > h2 {
    font-size: 18px;
    margin-bottom: 20px;
    font-weight: 700;
}

.public_recruitment .notification {
    max-width: 346px;
    width: 100%;
    height: 91px;
    padding: 10px;
    /* background: #f9ecec 0% 0% no-repeat padding-box; */
    box-shadow: 0px 0px 13px #0000000f;
    border-radius: 16px;
    opacity: 1;
    position: fixed;
    top: 63px;
    right: 3%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    z-index: 100000;
    color: #000;
}

.public_recruitment .notification__success-icon {
    flex-shrink: 0;
    width: 75px;
    height: 75px;
    background: #1bbaac 0% 0% no-repeat padding-box;
    border-radius: 16px;
    margin: 8px;
    background-image: url("../../../images/publicRecruitmentModule/tick.svg");
    background-size: 44px;
    background-position: center;
}
.public_recruitment .notification__error-icon {
    width: 75px;
    height: 75px;
    background: #ba1b3d 0% 0% no-repeat padding-box;
    border-radius: 16px;
    margin: 8px;
    background-image: url("../../../images/publicRecruitmentModule/close.svg");
    background-size: 44px;
    background-position: center;
}

.public_recruitment .notification__msg p {
    margin: 0;
    margin-right: 10px;
    font-size: 13px;
    font-family: "PoppinsLight";
}

.public_recruitment .notification__close {
    flex-shrink: 0;
    cursor: pointer;
    background: url("../../../images/publicRecruitmentModule/close.svg");
    background-size: 16.38px;
    width: 16px;
    height: 16px;
    /* filter: invert(48%) sepia(79%) saturate(2476%) hue-rotate(86deg) brightness(118%) contrast(119%); */
    filter: invert(83%) sepia(11%) saturate(12%) hue-rotate(20deg) brightness(102%) contrast(86%) opacity(30%);
    /* opacity: .5; */
}

.public_recruitment .form-group {
    padding: 5px 10px;
    border: 1px solid #e6e6e6;
    border-radius: 5px;
    position: relative;
    margin: 0;
}
.public_recruitment .error-text {
    font-size: 10.2px;
    font-family: "PoppinsBold";
    color: rgb(255, 0, 0) !important;
    margin-bottom: 5px;
}

.public_recruitment .form-control:focus {
    /* background-color: transparent; */
    border-color: transparent;
    outline: 0;
    box-shadow: none;
}

.public_recruitment label.form-label {
    position: absolute;
    top: 0px;
    margin-left: 3px;
    padding: 3px 8px;
    background-color: #fff;
    /* font-size: 14px; */
    font-family: "PoppinsRegular";
}

.public_recruitment .select-job {
    border: none;
    height: 35px;
    outline: none !important;
    border: transparent !important;
    box-shadow: none !important;
    font-size: 13px;
}

.public_recruitment .form-control {
    border: none !important;
    font-size: 13px;
}

.public_recruitment select.form-control {
    border: transparent;
    outline: none !important;
    border: transparent !important;
    box-shadow: none !important;
    font-size: 13px;
}

.public_recruitment div#firstGrpCntrl {
    margin-bottom: 0;
}

.public_recruitment .formDevider {
    margin: 30px;
}

.public_recruitment div#cvGrp {
    border: transparent;
}

.public_recruitment .form-file {
    margin: 10px 0 0 0;
}

.public_recruitment button.sbmtForm.btn.btn-primary {
    width: 100%;
    height: 60px;
    border: none;
    background-color: #19b0d2;
    margin: 10px 0 5px;
    font-size: 17px;
    font-family: "PoppinsBold";
}

.public_recruitment .form-text {
    font-size: 10.2px;
    font-family: "PoppinsLight";
    color: #000 !important;
}

.public_recruitment input#exampleFormControlFile1 {
    font-size: 14px;
}

.public_recruitment .css-yk16xz-control {
    border: none !important;
}

/* .public_recruitment .new-button {
    display: inline-block;
    padding: 8px 12px;
    cursor: pointer;
    background: #F0F0F0 0% 0% no-repeat padding-box;
    border: 1px solid #AFAEAE;
    border-radius: 5px;
    font-size: 14px;
    font-family: "PoppinsRegular";
    color: #fff; 
} */

.public_recruitment input[type="file"] {
    /* position: absolute;
    z-index: -1;
    top: 0;
    left: 64px; */
    font-size: 15px;
    color: rgb(153, 153, 153);
    background-color: transparent;
    max-width: 100%;
}
.public_recruitment .form-control:focus {
    box-shadow: none !important;
}

.public_recruitment .button-wrap {
    position: relative;
    margin-top: 14px;
    z-index: 0;
}

.public_recruitment input[type="file"]::file-selector-button {
    display: inline-block;
    padding: 8px 20px;
    cursor: pointer;
    background: #f0f0f0 0% 0% no-repeat padding-box;
    border: 1px solid #afaeae;
    border-radius: 5px;
    font-size: 14px;
    font-family: "PoppinsRegular";
}

.indicatifs {
    position: absolute;
    top: 13px;
    left: 30px;
    z-index: 100;
    background-color: #fff;
    cursor: pointer;
}
.indicatifcontainer {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.indicatifs img {
    margin-right: 8px;
}
.indicatifs div {
    margin-right: 4px;
}
.input-tel {
    padding-left: 100px !important;
}
/* .input-tel + .label-login {
    display: none;
} */
/* .input-tel + label {
    margin-left: 100px;
} */
.input-tel::placeholder {
    font-weight: 400;
    color: #808080;
}

.indicatifs__down {
    position: absolute;
    top: 30px;
    left: 0;
    right: -30;
    background-color: #fff;
    border-radius: 8px;
}
.indicatifs__downitem {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 8px;
}
.indicatifs__downitem {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 8px;
}
.indicatifs__downitem:hover {
    background-color: #19b0d214;
}
