.public_recruitment {
    /* background-color: #fff; */
    /* z-index: -1; */
}
.public_recruitment .OfrLstHeader {
    background-image: linear-gradient(120deg, rgba(19, 198, 221, 0.89), rgba(10, 97, 111, 0.89)),
        url("../../../images/publicRecruitmentModule/img1.jpg");
    /* height: 200px; */
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    position: relative;
    /* padding: 15px; */
    /* line-height: 200px; */
    display: flex;
    justify-content: center;
    /* align-items: center; */
    text-align: center;
    margin-bottom: 2%;
}
/* .HeaderImg{
    width: -webkit-fill-available;
    height: 100%;
}

.HeaderImgCover{
    width: 100%;
    position: absolute;
    top: 0;
    height: 200px;
    display: block;
}

.contentHeaderOfrLst{
    position: absolute;
    top: 0;
    width: 100%;
    height: 200px;
    text-align: center;
    
}
.contentHeaderOfrLst> div{
    max-width: 1100px;
    margin: auto;
    height: 200px;
    position: relative;
} */
.public_recruitment .ofrLstTxt {
    /* position: relative; */
    /* top: 30%; */
    width: 60%;
    margin: auto;
    padding: 7% 0;
}
.public_recruitment .ofrLstTxt p {
    font-family: "PoppinsRegular";
    font-size: 29px;
    margin-bottom: 1rem;
    color: #fff;
    /* padding: 15px; */
}
.public_recruitment .fw-bold {
    font-family: "PoppinsBold";
}
.public_recruitment .sobrusO {
    left: 0;
    position: absolute;
    bottom: 0;
}

.public_recruitment .quote {
    position: absolute;
    right: 0;
    bottom: 0;
}

/* .body{
    max-width: 1280px;
    margin: auto;
    padding: 25px 80px 0;
    text-align: center;
} */

@media screen and (max-width: 850px) {
    .public_recruitment .ofrLstTxt {
        width: 98%;
    }

    .public_recruitment .ofrLstTxt p {
        font-size: 20px;
    }
}
